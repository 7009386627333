import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"id":"channel-list"}},[_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex align-center pb-5"},[_c('router-link',{attrs:{"to":'/mitarbeiter/erstellen'}},[_c(VBtn,{staticClass:"me-3",attrs:{"color":"primary"}},[_c('span',[_vm._v("Mitarbeiter erstellen")])])],1)],1)]),_c(VDataTable,{staticClass:"text-no-wrap",attrs:{"headers":_vm.headers,"items":_vm.listEmployees,"options":_vm.options,"server-items-length":_vm.totalAmountList,"items-per-page":_vm.options.itemsPerPage,"loading":_vm.loading,"no-data-text":"Keine Einträge vorhanden","no-results-text":"Ihre Suche ergab keinen Treffer","footer-props":{
      itemsPerPageText: 'Anzahl pro Seite',
      itemsPerPageAllText: 'Alle',
      pageText: '{0}-{1} von {2}',
      itemsPerPageOptions: [5,10,25,50,-1]
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VDialog,{attrs:{"width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c(VCard,[_c(VCardText,{staticClass:"text-center"},[_vm._v(" Möchten Sie "),_c('strong',[_vm._v(" "+_vm._s(_vm.deleteItem.vorname)+" "+_vm._s(_vm.deleteItem.name)+" ")]),_vm._v(" wirklich löschen? ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.deleteEmployeeConfirm}},[_vm._v(" Löschen ")]),_c(VBtn,{attrs:{"color":"secondary","outlined":""},on:{"click":_vm.closeDelete}},[_vm._v(" Abbrechen ")]),_c(VSpacer)],1)],1)],1)]},proxy:true},{key:"item.idNipKundeMitarbeiter",fn:function(ref){
    var item = ref.item;
return [_vm._v(" #"+_vm._s(item.idNipKundeMitarbeiter)+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',{},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('router-link',{attrs:{"to":'/mitarbeiter/editieren/'+item.idNipKundeMitarbeiter}},[_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Bearbeiten")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.deleteEmployee( item )}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Löschen")])])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }