<template>
  <v-card id="channel-list">
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">
        <router-link :to="'/mitarbeiter/erstellen'" >
          <v-btn
            color="primary"
            class="me-3"
          >
            <span>Mitarbeiter erstellen</span>
        </v-btn>
        </router-link>
      </div>
    </v-card-text>

    <v-data-table
      :headers="headers"
      :items="listEmployees"
      :options.sync="options"
      :server-items-length="totalAmountList"
      :items-per-page="options.itemsPerPage"
      :loading="loading"
      class="text-no-wrap"
      no-data-text="Keine Einträge vorhanden"
      no-results-text="Ihre Suche ergab keinen Treffer"
      :footer-props="{
        itemsPerPageText: 'Anzahl pro Seite',
        itemsPerPageAllText: 'Alle',
        pageText: '{0}-{1} von {2}',
        itemsPerPageOptions: [5,10,25,50,-1]
      }"
    >
      <template
        v-slot:top
      >
        <v-dialog
          v-model="dialogDelete"
          width="500px"
        >
          <v-card>
            <v-card-text
              class="text-center"
            >
              Möchten Sie <strong>&nbsp;{{ deleteItem.vorname }} {{ deleteItem.name }}&nbsp;</strong> wirklich löschen?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="deleteEmployeeConfirm"
              >
                Löschen
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                @click="closeDelete"
              >
                Abbrechen
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </template>
      <template #[`item.idNipKundeMitarbeiter`]="{item}">
        #{{ item.idNipKundeMitarbeiter }}
      </template>

      <template #[`item.actions`]="{item}">
        <div class="">

          <v-tooltip
            bottom
          >
            <template #activator="{ on, attrs }">
              <router-link :to="'/mitarbeiter/editieren/'+item.idNipKundeMitarbeiter" >
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                </v-btn>
              </router-link>
            </template>
            <span>Bearbeiten</span>
          </v-tooltip>
          <v-tooltip
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="deleteEmployee( item )"
              >
                <v-icon size="18">
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Löschen</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
} from '@mdi/js'
import axios from "axios";
import {getCurrentInstance, ref} from "@vue/composition-api";
import { avatarText } from '@core/utils/filter'
import router from '@/router'

export default {
  name: 'channel-list',
  setup() {
    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 1000;

    return {
      vm,
      router
    }
  },
  data() {
    return {
      avatarText:avatarText,
      actions: ['Delete', 'Edit'],
      icons: {
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
      },
      loading: false,
      totalAmountList: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['idNipKundeMitarbeiter'],
        sortDesc: [true],
      },
      headers: [
        {
          text: '#',
          align: 'start',
          value: 'idNipKundeMitarbeiter',
        },
        {text: 'Vorname', value: 'vorname'},
        {text: 'Name', value: 'name',sortable: false},
        {text: 'E-Mail', value: 'email'},
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ],
      listEmployees: [],
      dialogDelete: false,
      dialogEdit: false,
      deleteItem: {
        idNipKundeMitarbeiter: 0,
        name: '',
      },
      editIndex: -1,
      editValid : false,
      editItem: {
        idNipKundeMitarbeiter: 0,
        name: '',
        memo: '',
      },
      defaultItem: {
        idNipKundeMitarbeiter: 0,
        name: '',
        memo: '',
      },
      editValidationError : true,

    }
  },
  mounted() {
    this.fetchEmployees()
  },
  watch: {
    options: {
      handler() {
        this.fetchEmployees()
      },
    },
    filter: {
      handler() {
        this.fetchEmployees()
      },
      deep: true,
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    dialogEdit (val) {
      val || this.closeEdit()
    },
  },
  computed: {
    formTitle () {
      return this.editIndex === -1 ? 'Mitarbeiter hinzufügen' : 'Mitarbeiter bearbeiten'
    },
    getBirthdayText() {
      return text => { return text ? `(${text})` : ''}
    },
  },
  methods: {
    fetchEmployees() {
      this.loading = true
      axios.post('api/mitarbeiter/', {
        aktion: 'showOverviewCustomerEmployee',
        filter: this.filter,
        options: encodeURIComponent(JSON.stringify(this.options)),
      })
        .then((response) => {
          if (response.data.valid === true) {
            this.listEmployees = response.data.employees || []
            this.totalAmountList = parseInt(response.data.total) || 0
            this.loading = false
          } else {
            console.log(response.data.errors)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    deleteEmployee (item) {
      this.dialogDelete = true
      this.deleteItem = JSON.parse(JSON.stringify(item))
    },
    deleteEmployeeConfirm () {
      axios.post('api/mitarbeiter/', {
        aktion: 'deleteCustomerEmployee',
        inputData :{idNipKundeMitarbeiter: this.deleteItem.idNipKundeMitarbeiter},
      })
        .then((response) => {
          this.vm.$toastr.s(response.data.message.title, response.data.message.text);
          this.fetchEmployees()
        })
        .catch((error) => {
          console.log(error)
        })

      this.closeDelete()
    },
    closeDelete () {
      this.dialogDelete = false
    },
    nameRules (v) {
      this.editValidationError = true
      if(!v) {
        return 'Bitte füllen Sie dieses Feld aus';
      }
      else if((v || '').length < 2) {
        return 'Der Name muss mindetens 2 Zeichen besitzen.';
      }
      else if((v || '').length > 45) {
        return 'Der Name kann maximal 45 Zeichen lang sein.';
      }

      this.editValidationError = false
      return true
    },
    editEmployeeConfirm () {
      axios.post('api/mitarbeiter/', {
        aktion: this.editIndex === -1 ? 'createCustomerEmployeeCheck' : 'editCustomerEmployeeCheck',
        data: this.editItem,
      })
        .then((response) => {
            if (response.data.valid === true) {
              this.vm.$toastr.s(response.data.message.title, response.data.message.text);
              this.fetchEmployees()
            }
            else {
              response.data.errors.forEach(item=>{
                let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
                this.vm.$toastr.e(item.errorText, item.errorTitle+suffix);
              })
            }
          this.closeEdit()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    closeEdit () {
      this.$nextTick(() => {
        this.dialogEdit = false
        this.editIndex = -1
        this.editItem = this.defaultItem
      })
    },
    updateFilter (key,value) {
      this.$nextTick(() => {
        this.filter[key] = value;
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.v-card__title {
  word-break: normal;
}
#channel-list {
  .channel-list-search {
    max-width: 10.625rem;
  }
  .channel-list-angabeAktiv {
    max-width: 11.3rem;
  }
  .channel-list-angabeArchiviert {
    max-width: 11.3rem;
  }
}
</style>
